import MessageMap from '../domains/MessageMap';

const ptBR: MessageMap = {
  CategoriesRoute: {
    PAGE_LAYOUT_TITLE: 'Classes',
  },
  NewCategoryButton: {
    NEW_CATEGORY_BUTTON_LABEL: 'Nova Classe',
  },
  CreateCategoryForm: {
    NEW_CATEGORY: 'Nova Classe',
    NEW_SUB_CATEGORY: 'Nova Subclasse',
  },
  CreateCategoryFormView: {
    DESCRIPTION: 'Descrição',
    SUB_CATEGORY_DESCRIPTION_HINT_TEXT: 'Insira uma breve descrição da subclasse',
    DESCRIPTION_HINT_TEXT: 'Insira uma breve descrição da classe',
    REQUIRED: 'Obrigatório',
    CATEGORY_NAME: 'Título *',
    TOOLTIP_HINT_TEXT: 'Insira abaixo o texto que aparecerá no campo.',
  },
  DialogFormView: {
    CANCEL_BUTTON_LABEL: 'Cancelar',
    CREATE_BUTTON_LABEL: 'Criar',
  },
  CategoryTreeItemLabel: {
    DETAILS: 'DETALHES',
    CATEGORY: 'CLASSE',
    SUBCATEGORY: 'SUBCLASSE',
    NOSUBCLASS: 'Nenhuma subclasse inserida.',
  },
  TabWrapper: {
    TITLE: 'Este produto tem',
    LEGACY: 'Atributos Legado',
    DYNAMIC: 'Atributos Dinâmicos',
    NO_RESULT_INITIAL: 'Esta classe não possui',
    NO_RESULT_MIDDLE: 'você gostaria de',
    NO_RESULT_FINAL: '?',
    NO_RESULT_LINK: 'criar',
  },
  CategoryDetailsRoute: {
    PAGE_LAYOUT_CAPTION: 'Classes',
    CURRENT_PAGE_TITLE: 'Detalhes',
  },
  AssociateAttributeModelsToCategory: {
    UNABLE_ASSOCIATE_ATTRIBUTE_CATEGORY: 'Não é possível associar atributos à classe',
    UNABLE_TO_EDIT_ATTRIBUTES: 'Não é possível editar atributos',
    ATTRIBUTE_ADDED: 'Atributos adicionados',
    ATTRIBUTE_EDITED: 'Atributos editados',
    ATTRIBUTE_ADDED_SINGLE: 'Atributo adicionado',
    ATTRIBUTE_EDITED_SINGLE: 'Atributo editado',
  },
  AssociateAttributeModalView: {
    TITLE: 'Inserir atributos',
  },
  AssociateAttributeModalActionsView: {
    COUNTER_LABEL_SINGLE: 'atributo selecionado',
    COUNTER_LABEL: 'atributos selecionados',
    CANCEL_LABEL: 'CANCELAR',
    SUBMIT_LABEL: 'ADICIONAR',
  },
  CategoryAttributesCardView: {
    CARD_TITLE: 'Atributos',
    BUTTON_LABEL: 'ADICIONAR ATRIBUTOS',
    BUTTON_TOOLTIP:
      'Você não pode adicionar atributos a uma classe que já possui produtos. Se você quiser mais atributos, você precisa criar uma nova classe.',
  },
  CategoryAttributesTable: {
    TITLE_HEADER_TABLE: 'Título',
    DESCRIPTION_HEADER_TABLE: 'Descrição',
    ACCESS_HEADER_TABLE: 'Acesso',
    EMPTY_TABLE_MESSAGE: 'Os atributos que você adicionou serão exibidos aqui.',
  },
  CategoryAttributesTableRowView: {
    REQUIRED: 'Obrigatório',
    OPTIONAL: 'Opcional',
  },
  CategoryDetailsLastEditedView: {
    TITLE: 'Última edição',
  },
  CategoryDetailsProductView: {
    TITLE: 'Produtos da classe',
  },
  CategoryDetailsCardView: {
    DETAILS: 'Detalhes',
    NAME: 'Nome',
    DESCRIPTION: 'Descrição',
    SEMANTIC_ID: 'Semantic ID',
    COPIED: 'Copiado!',
  },
  AttributesRoute: {
    TITLE: 'Atributos',
    BUTTON_ADD_ATTRIBUTE: 'Novo Atributo',
    CAPTION: 'Gerenciar todos os seus atributos.',
  },
  AttributeCard: {
    DATA_TYPE: 'Tipo de dados',
    DATA_TYPE_TOOLTIP: 'Qual tipo de dados esse atributo pode receber.',
    GROUP: 'Grupo',
    EDIT: 'Editar',
    STRUCTURE: 'Estrutura',
    STRUCTURE_TOOLTIP: 'A qual estrutura pertence esse atributo.',
  },
  AttributeCardAccordion: {
    ATTRIBUTES: 'Atributos',
  },
  AttributeGroupInputView: {
    ADD_ATTRIBUTE: 'Inserir atributo *',
    ORDER_CHOICE_ATTRIBUTES: 'A ordem de exibição terá como base o modo de seleção',
    SELECT_ATTRIBUTE: 'Selecionar atributo',
  },
  AttributeGroupInputListItemView: {
    REMOVE: 'Remover',
  },
  AttributeHeaderView: {
    LABEL_ADD_ATTRIBUTE: 'Criar Novo Attributo',
    TITLE_ATTRIBUTES: 'Atributos',
  },
  AttributeTablePagination: {
    ROWS_PER_PAGE_LABEL: 'Itens por página',
  },
  AttributeTableView: {
    LIST: 'Lista',
    GRID: 'Grupo',
  },
  AttributeListView: {
    TITLE: 'Título do atributo',
    DESCRIPTION: 'Descrição',
    DATA_TYPE: 'Tipo de dados',
    STRUCTURE: 'Estrutura',
    SEMANTIC_ID: 'Id semântico',
    SEMANTIC_ID_TOOLTIP: 'Identificador de atributo exclusivo',
  },
  FiltersModal: {
    APPLY_BUTTON: 'Aplicar',
    CANCEL_BUTTON: 'Cancelar',
  },
  AttributeCreatedDateFilterView: {
    INVALID_DATE_ERROR: 'Data inválida',
    MAX_START_DATE_ERROR: 'A data de término não pode ser posterior à data atual',
    MAX_START_DATE_ERROR_WITH_END_DATE: 'A data de início não deve ser posterior à data de término',
    MIN_END_DATE_ERROR_WITH_START_DATE: 'A data de término não deve ser anterior à data de início',
    MIN_END_DATE_ERROR: 'A data de término não deve ser anterior à 01/01/1899',
    MIN_START_DATE_ERROR: 'A data de início não deve ser anterior à 01/01/1899',
    MAX_END_DATE_ERROR: 'A data de término não pode ser posterior à data atual.',
    START_DATE: 'Data inicial',
    END_DATE: 'Data final',
  },
  AttributeFilters: {
    ATTRIBUTE_CREATED_DATE_FILTER: 'Criado em',
    ATTRIBUTE_DATA_TYPE_FILTER: 'Tipo de dados',
    FILTER: 'Filtros',
    SEARCH_ATTRIBUTE_PLACEHOLDER: 'Título do atributo',
    SEARCH_BUTTON: 'Busca',
  },
  FilterChipList: {
    ATTRIBUTE_DATA_TYPE_FILTER: 'Tipo de dados',
    ATTRIBUTE_CREATED_DATE_FILTER: 'Criado em',
    ATTRIBUTE_START_DATE_FILTER: 'Data inicial',
    ATTRIBUTE_END_DATE_FILTER: 'Data final',
  },
  FilterChipListView: {
    ATTRIBUTE_START_DATE_FILTER_CHIP: 'De',
    ATTRIBUTE_END_DATE_FILTER_CHIP: 'Até',
    ATTRIBUTE_START_AND_END_DATE_FILTER: 'até',
  },
  AttributeTableRow: {
    EDIT: 'Editar',
  },
  AttributeType: {
    FREE_TEXT: 'Campo de texto',
    GROUP: 'Grupo',
    SINGLE_SELECT_DROPDOWN: 'Lista de seleção única',
    RADIO_BUTTON: 'Radio Button',
  },
  NewAttributeView: {
    DETAILS: 'Detalhes',
    ATTRIBUTES: 'Atributos',
    CANCEL_LABEL: 'Cancelar',
    CREATE_BUTTON_LABEL: 'Criar',
    SAVE_BUTTON_LABEL: 'Salvar',
    DISCARD_MODAL_TITLE: 'Descartar atributo',
    DISCARD_MODAL_CONTENT: `Tem certeza que você quer excluir esse produto? Toda informação inserida será perdida.`,
    CONFIRM_LABEL: 'Descartar',
    DISCARD_BUTTON_LABEL: 'Descartar',
  },
  NewAttributeFormView: {
    FORM_TITLE: 'Novo Atributo',
    SUBMIT_LABEL: 'Salvar',
    CANCEL_LABEL: 'Cancelar',
    LABEL_NAME_INPUT: 'Nome',
    LABEL_TITLE_INPUT: 'Título *',
    LABEL_DESCRIPTION_INPUT: 'Descrição *',
    LABEL_TYPE_INPUT: 'Tipo',
    LABEL_FIELD_ARRAY_VALUE: 'Valores *',
    PLACEHOLDER_ENTER_VALUE: 'Inserir valor',
    REQUIRED_MESSAGE: 'Obrigatório',
    ERROR_DUPLICATED_VALUE: 'O valor inserido já está sendo usado',
    MORE_THAN_ONE_MESSAGE: 'Para continuar, adicione mais de 1 valor',
    INVALID_DATE_MESSAGE: 'Data inválida',
    GROUP_MUST_CONTAIN_LEAST_TWO_ATTRIBUTES: 'Um grupo deve conter pelo menos 2 atributos',
    LABEL_FIELD_TYPE_INPUT: 'Tipo de campo *',
    LABEL_DATA_TYPE_INPUT: 'Tipo de dados *',
    TITLE_ENTERED_IS_ALREADY_ASSOCIATED_ATTRIBUTE:
      'O título inserido já está associado a um atributo',
    EDIT_FORM_TITLE: 'Editar atributo',
    LABEL_TOOLTIP_INPUT: 'Informação',
    IS_DYNAMIC: 'Este atributo é dinâmico?',
    IS_DYNAMIC_TOOLTIP: 'Atributos cuja posição pode ser alterada em um nível de classe.',
  },
  NewAttributesView: {
    TITLE: 'Novo atributo',
  },
  DrawerFormView: {
    INFORMATION_MISSED: 'Toda informação inserida será perdida.',
    ARE_YOU_SURE: 'Tem certeza que quer fechar a janela?',
    CANCEL_BUTTON_LABEL: 'Cancelar',
    CREATE_BUTTON_LABEL: 'Criar',
  },
  DialogView: {
    CANCEL_BUTTON_LABEL: 'Cancelar',
    CONFIRM_BUTTON_LABEL: 'Fechar',
  },
  CreateAttributeUseCase: {
    NEW_ATTRIBUTE_CREATED: 'Novo atributo criado',
  },
  CategoryDetailsPath: {
    TITLE: 'Hierarquia de classe',
  },
  AttributeDetails: {
    DETAILS: 'Detalhes',
    ATTRIBUTES: 'Atributos',
    GROUP: 'Grupo',
    SINGLE_SELECT_DROPDOWN: 'Lista de seleção única',
    FREE_TEXT: 'Campo de texto',
    STRING: 'Linha',
    NUMERIC: 'Numérico',
    DATE: 'Data',
    DESCRIPTION: 'Descrição',
    TOOLTIP: 'Informação',
    FIELD_TYPE: 'Tipo de campo',
    DATA_TYPE: 'Tipo de dados',
    VALUES: 'Valores',
    RADIO_BUTTON: 'Radio Button',
    SEMANTIC_ID: 'Id semântico',
    COPY_MESSAGE: 'Copiado!',
  },
  AttributeStructureTag: {
    LEGACY: 'Legado',
    DYNAMIC: 'Dinâmico',
  },
  DynamicAttributeListView: {
    TABLE_HEADER_ATTRIBUTE_NAME: 'Nome do atributo',
    TABLE_HEADER_ATTRIBUTE_DESCRIPTION: 'Descrição',
    TABLE_HEADER_ATTRIBUTE_OPTIONAL: 'Opcional',
    TABLE_HEADER_ATTRIBUTE_REQUIRED: 'Obrigatório',
    TABLE_HEADER_ATTRIBUTE_FORMAT: 'Formato',
  },
  DynamicAttributeRowView: {
    TABLE_CELL_REQUIRED: 'Obrigatório',
    TABLE_CELL_OPTIONAL: 'Opcional',
    REQUIRED_ICON_IMAGE: 'Icone obrigatório',
    OPTIONAL_ICON_IMAGE: 'Icone opcional',
  },
  DynamicAttributeListModalView: {
    TITLE: 'Nova classe',
    SUBTITLE: 'Agora gerencie a posição e o formato de cada atributo dinâmico de sua nova classe.',
    BUTTON_GO_BACK: 'Voltar',
    BUTTON_CONFIRM: 'Confirmar',
  },
  AssociateAttributeModalItemView: {
    STATUS_DYNAMIC: 'Dinâmico',
    STATUS_LEGACY: 'Legado',
  },
  FormikInputBoolean: {
    YES: 'Sim',
    NO: 'Não',
    NONE: 'Nenhum',
  },
};

export default ptBR;
