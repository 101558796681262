import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      flex: 1,
    },
    containerBody: {
      display: 'flex',
      width: '100%',
    },
    loadingView: {
      left: 'calc(50% - 22px)',
      top: 'calc(50% - 22px)',
      position: 'absolute',
    },
  })
);

export default useStyles;
