import MessageMap from '../domains/MessageMap';

const koKR: MessageMap = {
  AttributesRoute: {
    TITLE: '속성',
    CAPTION: '모든 속성 관리',
    BUTTON_ADD_ATTRIBUTE: '새로운 속성',
  },
  AttributeTablePagination: {
    ROWS_PER_PAGE_LABEL: '페이지 당 카드',
  },
  AttributeTableView: {
    LIST: '목록',
    GRID: '그리드',
  },
  AttributeListView: {
    TITLE: '속성 제목',
    DESCRIPTION: '설명',
    DATA_TYPE: '데이터 타입',
    STRUCTURE: '구조',
    SEMANTIC_ID: '시맨틱 ID',
    SEMANTIC_ID_TOOLTIP: '고유 속성 식별자',
  },
  AttributeCard: {
    DATA_TYPE: '데이터 타입',
    DATA_TYPE_TOOLTIP: '이 속성이 수신할 수 있는 데이터입니다.',
    GROUP: '그룹',
    EDIT: '수정',
    STRUCTURE: '구조',
    STRUCTURE_TOOLTIP: '이 속성이 속한 구조입니다.',
  },
  AttributeFilters: {
    ATTRIBUTE_CREATED_DATE_FILTER: '생성일',
    ATTRIBUTE_DATA_TYPE_FILTER: '데이터 타입',
    FILTER: '필터',
    SEARCH_ATTRIBUTE_PLACEHOLDER: '속성 제목으로 검색',
    SEARCH_BUTTON: '검색',
  },
  FiltersModal: {
    APPLY_BUTTON: '적용',
    CANCEL_BUTTON: '취소',
  },
  AttributeType: {
    FREE_TEXT: '입력창',
    GROUP: '그룹',
    SINGLE_SELECT_DROPDOWN: '단일 선택 리스트',
    RADIO_BUTTON: '라디오버튼',
  },
  AttributeCreatedDateFilterView: {
    INVALID_DATE_ERROR: '잘못된 날짜',
    MAX_START_DATE_ERROR: '시작 날짜는 현재 날짜 이후일 수 없습니다.',
    MAX_START_DATE_ERROR_WITH_END_DATE: '시작 날짜는 종료 날짜 이후일 수 없습니다.',
    MIN_END_DATE_ERROR_WITH_START_DATE: '종료 날짜는 시작 날짜 이전이 아니어야 합니다.',
    MIN_END_DATE_ERROR: '종료 날짜는 1899년 1월 1일 이전이어야 합니다.',
    MIN_START_DATE_ERROR: '시작 날짜는 1899년 1월 1일 이전이어야 합니다.',
    MAX_END_DATE_ERROR: '종료 날짜는 현재 날짜 이후일 수 없습니다.',
    START_DATE: '시작일',
    END_DATE: '종료일',
  },
  FilterChipListView: {
    ATTRIBUTE_START_DATE_FILTER_CHIP: '에서',
    ATTRIBUTE_END_DATE_FILTER_CHIP: '까지',
    ATTRIBUTE_START_AND_END_DATE_FILTER: '에게',
  },
  FilterChipList: {
    ATTRIBUTE_DATA_TYPE_FILTER: '데이터 타입',
    ATTRIBUTE_CREATED_DATE_FILTER: '생성일',
    ATTRIBUTE_START_DATE_FILTER: '시작일',
    ATTRIBUTE_END_DATE_FILTER: '종료일',
  },
  CategoryDetailsCardView: {
    DETAILS: '상세',
    NAME: '이름',
    DESCRIPTION: '설명',
    TOOLTIP: '툴팁',
    SEMANTIC_ID: '시맨틱 ID',
    COPIED: '복사되었습니다!',
  },
  AttributeTableRow: {
    EDIT: '수정',
  },
  AttributeCardAccordion: {
    ATTRIBUTES: '속성',
  },
  AttributeStructureTag: {
    LEGACY: '레거시',
    DYNAMIC: '동적인',
  },
  CategoryAttributesCardView: {
    BUTTON_TOOLTIP:
      '이미 제품이 있는 클래스에는 속성을 추가할 수 없습니다. 더 많은 속성을 원하면 새 클래스를 만들어야 합니다.',
  },
  NewAttributeView: {
    DETAILS: '상세',
    ATTRIBUTES: '속성',
    CANCEL_LABEL: '취소',
    CREATE_BUTTON_LABEL: '생성',
    SAVE_BUTTON_LABEL: '저장',
    DISCARD_MODAL_TITLE: '버리기 속성',
    DISCARD_MODAL_CONTENT: `이 속성을 삭제하시겠습니까? 추가된 모든 정보가 손실됩니다.`,
    CONFIRM_LABEL: '버리기',
    DISCARD_BUTTON_LABEL: '버리기',
  },
  NewAttributeFormView: {
    FORM_TITLE: '새로운 속성',
    SUBMIT_LABEL: '저장',
    CANCEL_LABEL: '취소',
    LABEL_NAME_INPUT: '이름',
    LABEL_TITLE_INPUT: '이름 *',
    LABEL_DESCRIPTION_INPUT: '설명 *',
    LABEL_TOOLTIP_INPUT: '툴팁',
    LABEL_TYPE_INPUT: '타입',
    LABEL_FIELD_ARRAY_VALUE: '값 *',
    PLACEHOLDER_ENTER_VALUE: '값 입력',
    REQUIRED_MESSAGE: '필수 항목',
    ERROR_DUPLICATED_VALUE: '입력하신 값은 이미 사용중입니다.',
    MORE_THAN_ONE_MESSAGE: '계속하시려면 1개 이상의 값을 추가하십시오.',
    INVALID_DATE_MESSAGE: '잘못된 날짜',
    GROUP_MUST_CONTAIN_LEAST_TWO_ATTRIBUTES: '그룹에는 2개 이상의 속성이 포함되어야 합니다',
    LABEL_FIELD_TYPE_INPUT: '필드 타입 *',
    LABEL_DATA_TYPE_INPUT: '데이터 타입 *',
    TITLE_ENTERED_IS_ALREADY_ASSOCIATED_ATTRIBUTE:
      '입력하신 타이틀은 이미 속성에 할당되어 있습니다',
    EDIT_FORM_TITLE: '속성 수정',
    IS_DYNAMIC: '동적인 속성입니까?',
    IS_DYNAMIC_TOOLTIP: '클래스 수준에서 위치를 변경할 수 있는 속성입니다.',
  },
  NewAttributesView: {
    TITLE: '새로운 속성',
  },
  AttributeGroupInputView: {
    ADD_ATTRIBUTE: '속성 추가 *',
    ORDER_CHOICE_ATTRIBUTES: '표시 순서는 선택 항목을 기반으로 합니다',
    SELECT_ATTRIBUTE: '선택 속성',
  },
  DrawerFormView: {
    DEFAULT_SUBMIT_LABEL: '저장',
    DEFAULT_CANCEL_LABEL: '취소',
    INFORMATION_MISSED: '닫으시겠습니까? 추가된 모든 정보가 손실됩니다',
    ERROR_DESCRIPTION: '죄송합니다. 기술적인 문제가 있습니다.\n나중에 다시 시도하세요.',
    ERROR_CLOSE_BUTTON: '닫기',
    ARE_YOU_SURE: '닫으시겠습니까? 추가된 모든 정보가 손실됩니다',
  },
  DialogView: {
    CANCEL_BUTTON_LABEL: '취소',
    CONFIRM_BUTTON_LABEL: '닫기',
  },
  DialogFormView: {
    CANCEL_BUTTON_LABEL: '취소',
    CREATE_BUTTON_LABEL: '생성',
  },
  FormikInputBoolean: {
    YES: '예',
    NO: '아니오',
    NONE: '없음',
  },
  NewCategoryButton: {
    NEW_CATEGORY_BUTTON_LABEL: '새로운 수업',
  },
  CreateCategoryForm: {
    NEW_TOP_LEVEL_CATEGORY: '새로운 최상위 클래스',
    NEW_CHILD_CATEGORY: '새로운 자식 클래스',
    NEW_CATEGORY: '새로운 수업',
    NEW_SUB_CATEGORY: '하위 클래스 추가',
  },
  CreateCategoryFormView: {
    REQUIRED: '필수 항목',
    CATEGORY_NAME: '타이틀 *',
    DESCRIPTION: '설명 *',
    DESCRIPTION_HINT_TEXT: '수업에 대한 간단한 설명 추가',
    TOOLTIP: '툴팁',
    TOOLTIP_HINT_TEXT: '툴팁에 표시될 정보를 추가합니다.',
    THIS_CATEGORY_NAME_TAKEN: '이 클래스 이름을 사용합니다.',
    SUB_CATEGORY_DESCRIPTION_HINT_TEXT: '하위 클래스에 대한 간략한 설명을 추가합니다.',
  },
  CategoryTreeItemLabel: {
    DETAILS: '상세',
    CATEGORY: '클래스',
    SUBCATEGORY: '아강',
    NOSUBCLASS: '추가된 하위 클래스가 없습니다.',
  },
  CategoriesRoute: {
    PAGE_LAYOUT_TITLE: '클래스',
  },
};

export default koKR;
