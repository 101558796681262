import MessageMap from '../domains/MessageMap';

const en: MessageMap = {
  ValidationsMessageGeneric: {
    REQUIRED: 'This field must be filled to complete the edition',
    LABEL_REMOVE_BUTTON: 'Remove',
    LABEL_DESCRIPTION: 'Description',
  },
  CreateCategoryUseCase: {
    CATEGORY_CREATED: 'Class created',
    UNABLE_CREATE_CATEGORY: 'Unable to create class',
  },
  LoadCategoryDetailsUseCase: {
    UNABLE_LOAD_CATEGORY_DETAILS: 'Unable to load class details',
  },
  AttributesCard: {
    CARD_TITLE: 'Attributes',
  },
  LoadAttributesUseCase: {
    ERROR_LOAD_ALL_ATTRIBUTES: 'Unable to load attributes',
  },
  AttributeHeaderView: {
    LABEL_ADD_ATTRIBUTE: 'Create New Attribute',
    TITLE_ATTRIBUTES: 'Attributes',
  },
  AttributeListItemView: {
    EDIT_LINK: 'Edit',
    EDIT_VARIANT: 'Edit',
    UNLINK_VARIANT: 'This product variant is linked to SKUs. Unlink to edit',
  },
  AttributeTablePagination: {
    ROWS_PER_PAGE_LABEL: 'Cards per page',
  },
  AttributeTableView: {
    LIST: 'List',
    GRID: 'Grid',
  },
  AttributeListView: {
    TITLE: 'Attribute Title',
    DESCRIPTION: 'Description',
    DATA_TYPE: 'Data Type',
    STRUCTURE: 'Structure',
    SEMANTIC_ID: 'Semantic ID',
    SEMANTIC_ID_TOOLTIP: 'Unique attribute identifier',
  },
  AttributeTableRow: {
    EDIT: 'Edit',
  },
  AttributeType: {
    FREE_TEXT: 'Free Text',
    GROUP: 'Group',
    SINGLE_SELECT_DROPDOWN: 'Single Select Dropdown',
    RADIO_BUTTON: 'Radio Button',
  },
  CategoriesRoute: {
    PAGE_LAYOUT_TITLE: 'Classes',
  },
  CreateAttributeUseCase: {
    NEW_ATTRIBUTE_CREATED: 'New attribute created',
    UNABLE_CREATE_ATTRIBUTE: 'Unable to create attribute',
  },
  UpdateAttributeUseCase: {
    ATTRIBUTE_UPDATED: 'Attribute updated',
    UNABLE_UPDATE_ATTRIBUTE: 'Unable to update attribute',
  },
  FiltersModal: {
    APPLY_BUTTON: 'Apply',
    CANCEL_BUTTON: 'Cancel',
  },
  AttributeCreatedDateFilterView: {
    INVALID_DATE_ERROR: 'Invalid date',
    MAX_START_DATE_ERROR: 'Start date should not be after current date',
    MAX_START_DATE_ERROR_WITH_END_DATE: 'Start date should not be after end date',
    MIN_END_DATE_ERROR_WITH_START_DATE: 'End date should not be before start date',
    MIN_END_DATE_ERROR: 'End date should not be before 01/01/1899',
    MIN_START_DATE_ERROR: 'Start date should not be before 01/01/1899',
    MAX_END_DATE_ERROR: 'End date should not be after current date',
    START_DATE: 'Start date',
    END_DATE: 'End date',
  },
  AttributeFilters: {
    ATTRIBUTE_CREATED_DATE_FILTER: 'Created on',
    ATTRIBUTE_DATA_TYPE_FILTER: 'Data Type',
    FILTER: 'Filters',
    SEARCH_ATTRIBUTE_PLACEHOLDER: 'Search by attribute title',
    SEARCH_BUTTON: 'Search',
  },
  FilterChipList: {
    ATTRIBUTE_DATA_TYPE_FILTER: 'Data Type',
    ATTRIBUTE_CREATED_DATE_FILTER: 'Created on',
    ATTRIBUTE_START_DATE_FILTER: 'Start Date',
    ATTRIBUTE_END_DATE_FILTER: 'End Date',
  },
  FilterChipListView: {
    ATTRIBUTE_START_DATE_FILTER_CHIP: 'From',
    ATTRIBUTE_END_DATE_FILTER_CHIP: 'Until',
    ATTRIBUTE_START_AND_END_DATE_FILTER: 'to',
  },
  NewAttributeView: {
    DETAILS: 'Details',
    ATTRIBUTES: 'Attributes',
    CANCEL_LABEL: 'Cancel',
    CREATE_BUTTON_LABEL: 'Create',
    SAVE_BUTTON_LABEL: 'Save',
    DISCARD_MODAL_TITLE: 'Discard attribute',
    DISCARD_MODAL_CONTENT: `Are you sure you want to discard this attribute? All the information added will be lost.`,
    CONFIRM_LABEL: 'Discard',
    DISCARD_BUTTON_LABEL: 'Discard',
  },
  NewAttributeFormView: {
    FORM_TITLE: 'New Attribute',
    SUBMIT_LABEL: 'Save',
    CANCEL_LABEL: 'Cancel',
    LABEL_NAME_INPUT: 'Name',
    LABEL_TITLE_INPUT: 'Title *',
    LABEL_DESCRIPTION_INPUT: 'Description *',
    LABEL_TOOLTIP_INPUT: 'Tooltip',
    LABEL_TYPE_INPUT: 'Type',
    LABEL_FIELD_ARRAY_VALUE: 'Values *',
    PLACEHOLDER_ENTER_VALUE: 'Enter Value',
    REQUIRED_MESSAGE: 'Required',
    ERROR_DUPLICATED_VALUE: 'The entered value is already being used',
    MORE_THAN_ONE_MESSAGE: 'In order to continue, add more than 1 value',
    INVALID_DATE_MESSAGE: 'Invalid date',
    GROUP_MUST_CONTAIN_LEAST_TWO_ATTRIBUTES: 'A group must contain at least 2 attributes',
    LABEL_FIELD_TYPE_INPUT: 'Field type *',
    LABEL_DATA_TYPE_INPUT: 'Data type *',
    TITLE_ENTERED_IS_ALREADY_ASSOCIATED_ATTRIBUTE:
      'The title entered is already associated to an attribute',
    EDIT_FORM_TITLE: 'Edit Attribute',
    IS_DYNAMIC: 'Is this Attribute dynamic?',
    IS_DYNAMIC_TOOLTIP: 'Attributes which position can be changed at a class-level.',
  },
  NewAttributesView: {
    TITLE: 'New Attribute',
  },
  DrawerFormView: {
    DEFAULT_SUBMIT_LABEL: 'Save',
    DEFAULT_CANCEL_LABEL: 'Cancel',
    INFORMATION_MISSED: 'Are you sure you want to close? All the information added will be lost',
    ERROR_DESCRIPTION: "Sorry, we're having some technical issues.\nTry again later.",
    ERROR_CLOSE_BUTTON: 'Close',
    ARE_YOU_SURE: 'Are you sure you want to close? All the information added will be lost',
  },
  DialogView: {
    CANCEL_BUTTON_LABEL: 'Cancel',
    CONFIRM_BUTTON_LABEL: 'Close',
  },
  DialogFormView: {
    CANCEL_BUTTON_LABEL: 'Cancel',
    CREATE_BUTTON_LABEL: 'Create',
  },
  NewCategoryButton: {
    NEW_CATEGORY_BUTTON_LABEL: 'New class',
  },
  CreateCategoryForm: {
    NEW_TOP_LEVEL_CATEGORY: 'New Top Level Class',
    NEW_CHILD_CATEGORY: 'New child class',
    NEW_CATEGORY: 'New class',
    NEW_SUB_CATEGORY: 'New sub class',
  },
  CreateCategoryFormView: {
    REQUIRED: 'Required',
    CATEGORY_NAME: 'Title *',
    DESCRIPTION: 'Description *',
    DESCRIPTION_HINT_TEXT: 'Add a brief description of the class',
    TOOLTIP: 'Tooltip',
    TOOLTIP_HINT_TEXT: 'Add the information that appears in the tooltip hover.',
    THIS_CATEGORY_NAME_TAKEN: 'This class name is taken.',
    SUB_CATEGORY_DESCRIPTION_HINT_TEXT: 'Add a brief description of the sub class',
  },
  CategoryListHeaderView: {
    NEW_CATEGORY: 'NEW CLASS',
  },
  CategoryAttributesTable: {
    TITLE_HEADER_TABLE: 'Title',
    DESCRIPTION_HEADER_TABLE: 'Description',
    ACCESS_HEADER_TABLE: 'Access',
    EMPTY_TABLE_MESSAGE: 'The atributes you add will be displayed here.',
  },
  CategoryAttributesCardView: {
    CARD_TITLE: 'Attributes',
    BUTTON_LABEL: 'ADD ATTRIBUTES',
    BUTTON_TOOLTIP:
      "You can't add attributes to a class that already has products. If you want more attributes, you need to create a new class.",
  },
  LoadChildrenOfCategoryById: {
    UNABLE_LOAD_CATEGORIES: 'Unable to load top level classes',
    UNABLE_LOAD_CHILDREN: 'Unable to load the children',
  },
  LoadCategoriesByChildrenUseCase: {
    UNABLE_LOAD_CATEGORIES: 'Unable to load classes',
  },
  CategoryDetailsPath: {
    TITLE: 'Class Path',
  },
  CategoryDetailsProductView: {
    TITLE: 'Products in Class',
  },
  CategoryDetailsLastEditedView: {
    TITLE: 'Last Edited',
  },
  CategoryTreeItemLabel: {
    DETAILS: 'DETAILS',
    CATEGORY: 'CLASS',
    SUBCATEGORY: 'SUBCLASS',
    NOSUBCLASS: 'No sub class added.',
  },
  LoadNextAttributeModels: {
    ERROR_LOAD_ALL_ATTRIBUTES: 'Unable to load attributes',
  },
  CategoryDetailsCardView: {
    DETAILS: 'Details',
    NAME: 'Name',
    DESCRIPTION: 'Description',
    TOOLTIP: 'Tooltip',
    SEMANTIC_ID: 'Semantic ID',
    COPIED: 'Copied!',
  },
  CategoryAttributesTableRowView: {
    REQUIRED: 'Required',
    OPTIONAL: 'Optional',
  },
  CategoryDetailsRoute: {
    PAGE_LAYOUT_CAPTION: 'Classes',
    CURRENT_PAGE_TITLE: 'Details',
  },
  AssociateAttributeModelsToCategory: {
    UNABLE_ASSOCIATE_ATTRIBUTE_CATEGORY: 'Unable to associate attributes to class',
    UNABLE_TO_EDIT_ATTRIBUTES: 'Unable to edit attributes',
    ATTRIBUTE_ADDED: 'Attributes added',
    ATTRIBUTE_EDITED: 'Attributes edited',
    ATTRIBUTE_ADDED_SINGLE: 'Attribute added',
    ATTRIBUTE_EDITED_SINGLE: 'Attribute edited',
  },
  AssociateAttributeModalView: {
    TITLE: 'Add attributes',
  },
  AssociateAttributeModalActionsView: {
    COUNTER_LABEL_SINGLE: 'attribute selected',
    COUNTER_LABEL: 'attributes selected',
    CANCEL_LABEL: 'CANCEL',
    SUBMIT_LABEL: 'ADD',
  },
  AttributeGroupInputView: {
    ADD_ATTRIBUTE: 'Add attribute *',
    ORDER_CHOICE_ATTRIBUTES: 'The display order will be based on the selection',
    SELECT_ATTRIBUTE: 'Select attribute',
  },
  AttributeGroupInputListItemView: {
    REMOVE: 'Remove',
  },
  CategorySelector: {
    SECTION_TITLE: 'Select class',
    SECTION_DESCRIPTION:
      'Choose a class from the list below. It will define the attributes of your product.',
    INPUT_LABEL: 'Select class',
    INPUT_PLACEHOLDER: 'Class',
  },
  DeleteModalErrorView: {
    ERROR_MESSAGE_TITLE: 'Something went wrong',
    ERROR_MESSAGE_DESCRIPTION: "Sorry, we're having some technical issues. Please, try again.",
    CLOSE_BUTTON: 'CLOSE',
    GO_BACK_BUTTON: 'GO BACK',
  },
  AttributesRoute: {
    TITLE: 'Attributes',
    CAPTION: 'Manage all of your atributes.',
    BUTTON_ADD_ATTRIBUTE: 'New Attribute',
  },
  FormikInputBoolean: {
    YES: 'Yes',
    NO: 'No',
    NONE: 'None',
  },
  AttributeCardAccordion: {
    ATTRIBUTES: 'Attributes',
  },
  AttributeCard: {
    DATA_TYPE: 'Data Type',
    DATA_TYPE_TOOLTIP: 'Which data this attribute can receive.',
    GROUP: 'Group',
    EDIT: 'Edit',
    STRUCTURE: 'Structure',
    STRUCTURE_TOOLTIP: 'Which structure this attribute belongs to.',
  },
  ImageZoomModalButtonActionView: {
    COPY_URL: 'Copy picture URL',
    COPY_URL_TOOLTIP: 'Picture URL copied!',
    CLOSE: 'Close',
    DELETE_IMAGE: 'Delete image',
  },
  TabWrapper: {
    TITLE: 'This product has',
    LEGACY: 'Legacy Attributes',
    DYNAMIC: 'Dynamic Attributes',
    NO_RESULT_INITIAL: 'This class has no',
    NO_RESULT_MIDDLE: 'do you want to',
    NO_RESULT_FINAL: 'it?',
    NO_RESULT_LINK: 'create',
  },
  AttributeDetails: {
    DETAILS: 'Details',
    ATTRIBUTES: 'Attributes',
    GROUP: 'Group',
    SINGLE_SELECT_DROPDOWN: 'Single select dropdown',
    FREE_TEXT: 'Free text',
    STRING: 'String',
    NUMERIC: 'Numeric',
    DATE: 'Date',
    DESCRIPTION: 'Description',
    TOOLTIP: 'Tooltip',
    FIELD_TYPE: 'Field type',
    DATA_TYPE: 'Data type',
    VALUES: 'Values',
    RADIO_BUTTON: 'Radio Button',
    SEMANTIC_ID: 'Semantic ID',
    COPY_MESSAGE: 'Copied!',
  },
  AttributeStructureTag: {
    LEGACY: 'Legacy',
    DYNAMIC: 'Dynamic',
  },
  DynamicAttributeListView: {
    TABLE_HEADER_ATTRIBUTE_NAME: 'Attribute Name',
    TABLE_HEADER_ATTRIBUTE_DESCRIPTION: 'Description',
    TABLE_HEADER_ATTRIBUTE_OPTIONAL: 'Optional',
    TABLE_HEADER_ATTRIBUTE_REQUIRED: 'Required',
    TABLE_HEADER_ATTRIBUTE_FORMAT: 'Format',
  },
  DynamicAttributeRowView: {
    TABLE_CELL_REQUIRED: 'Required',
    TABLE_CELL_OPTIONAL: 'Optional',
    REQUIRED_ICON_IMAGE: 'Required icon',
    OPTIONAL_ICON_IMAGE: 'Optional icon',
  },
  DynamicAttributeListModalView: {
    TITLE: 'New Class',
    SUBTITLE: 'Now manage the position and format of each dynamic attribute of your new class.',
    BUTTON_GO_BACK: 'Go Back',
    BUTTON_CONFIRM: 'Confirm',
  },
  AssociateAttributeModalItemView: {
    STATUS_DYNAMIC: 'Dynamic',
    STATUS_LEGACY: 'Legacy',
  },
};

export default en;
