import { belongsTo, hasMany, Model } from 'miragejs';

const models = {
  product: Model.extend({
    variants: hasMany('variant'),
    productClass: belongsTo('productClass'),
    attributes: hasMany({ polymorphic: true }),
    parent: belongsTo('productClass', { inverse: 'children' }),
  }),
  productClass: Model.extend({
    children: hasMany('productClass', { inverse: 'parent' }),
    parent: belongsTo('productClass', { inverse: 'children' }),
    products: hasMany('product'),
    ancestors: hasMany('productClass', { inverse: 'descendants' }),
    descendants: belongsTo('productClass', { inverse: 'ancestors' }),
    attributes: hasMany({ polymorphic: true }),
  }),
  variant: Model.extend({
    vendorItems: hasMany('vendorItem'),
    product: belongsTo('product'),
  }),
  vendorItem: Model.extend({
    product: belongsTo('variant'),
  }),
  attributeModel: Model.extend({
    metadata: hasMany({ polymorphic: true }),
  }),
  // groupAttributeModelMetadata: Model.extend({
  //   subAttributes: hasMany('attributeModel')
  // }),
  // subAttribute: Model.extend({
  //   attributeModel: belongsTo('attributeModel')
  // }),
  metadata: Model.extend(),
  // groupAttributeModelMetadata: Model.extend(),
  // attributeModelMetadata: Model.extend({
  //   metadata: belongsTo('attributeModel')
  // }),
  // attributeModelMetadata: Model.extend(),
  attribute: Model.extend({
    product: belongsTo('product'),
    productClass: belongsTo('productClass'),
    attributeModel: belongsTo('attributeModel'),
  }),
};

models.concreteAttribute = models.attribute.extend({
  abstractAttribute: belongsTo('abstractAttribute'),
});
models.abstractAttribute = models.attribute.extend({
  concreteAttribute: belongsTo('concreteAttribute'),
});

models.groupAttributeModelMetadata = models.metadata.extend({
  subAttributes: hasMany('attributeModel'),
});
models.enumAttributeModelDateMetadata = models.metadata.extend();
models.enumAttributeModelNumericMetadata = models.metadata.extend();
models.enumAttributeModelStringMetadata = models.metadata.extend();

export default models;
