import { usePreferredLanguage } from 'admin-portal-shared-services';
import React from 'react';
import { PREFERRED_LANGUAGE } from '../config/constants';

export const i18nLanguageContext = React.createContext({
  language: '',
  setProviderLanguage: undefined,
});

interface Props {
  children: React.ReactNode;
}

export const I18nLanguageProvider = ({ children }: Props): JSX.Element => {
  const preferredLanguageFromService = usePreferredLanguage();
  const preferredLanguage =
    localStorage.getItem(PREFERRED_LANGUAGE) ?? preferredLanguageFromService;
  const [language, setProviderLanguage] = React.useState(preferredLanguage);

  return (
    <i18nLanguageContext.Provider value={{ language, setProviderLanguage }}>
      {children}
    </i18nLanguageContext.Provider>
  );
};
