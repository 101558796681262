export const PIM_EDIT_MANUFACTURER = 'pim-edit-manufacturer';
export const PIM_PLP_ADD_COLUMN = 'pim-plp-add-column';
export const PIM_SEARCH_ATTRIBUTES = 'pim-search-attributes';
export const PIM_PRODUCT_TABLE_CHECKBOX = 'pim-product-table-checkbox';
export const PIM_DELETE_IMAGE_ZOOM = 'pim-delete-image-zoom';
export const PIM_SPLIT_REPOSITORIES = 'pim-split-repositories';
export const PIM_PLP_COUNTRY_FILTER = 'pim-plp-country-filter';
export const PIM_SPLIT_PRODUCT_REPOSITORIES = 'pim-split-product-repositories';
export const PIM_ADMIN_PDP_ATTRIBUTE_REQUIRED = 'pim-admin-pdp-attribute-required';
export const PIM_BUTTONS_LAYOUT_IMPROVEMENT = 'pim-buttons-layout-improvement';
export const PIM_CLASSES_DROPDOWN = 'pim-classes-dropdown';
export const PIM_PERMISSION_PRODUCTS = 'pim-permission-products';
export const PIM_TRANSLATE_LABELS = 'pim-translate-labels';
export const PIM_NEW_ATTRIBUTE = 'pim-new-attribute';
export const PIM_ATTRIBUTES_FILTER = 'pim-attributes-filter';
export const PIM_ATTRIBUTE_DETAILS_PAGE = 'pim-attribute-details-page';
export const PIM_SIDE_MENU = 'pim-side-menu';
