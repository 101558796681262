import MessageMap from '../domains/MessageMap';

const es419: MessageMap = {
  CategoriesRoute: {
    PAGE_LAYOUT_TITLE: 'Clases',
  },
  NewCategoryButton: {
    NEW_CATEGORY_BUTTON_LABEL: 'Nueva clase',
  },
  CreateCategoryForm: {
    NEW_CATEGORY: 'Nueva Clase',
    NEW_SUB_CATEGORY: 'Nueva Subclase',
  },
  CreateCategoryFormView: {
    DESCRIPTION: 'Descripción',
    SUB_CATEGORY_DESCRIPTION_HINT_TEXT: 'Agrega una breve descripción de la sub clase',
    DESCRIPTION_HINT_TEXT: 'Agrega una breve descripción de la clase',
    REQUIRED: 'Obligatorio',
    CATEGORY_NAME: 'Título *',
    TOOLTIP_HINT_TEXT: 'Agregá la información que aparece en el globo de ayuda.',
  },
  DialogFormView: {
    CANCEL_BUTTON_LABEL: 'Cancelar',
    CREATE_BUTTON_LABEL: 'Crear',
  },
  CategoryTreeItemLabel: {
    DETAILS: 'DETALLES',
    CATEGORY: 'CLASE',
    SUBCATEGORY: 'SUB CLASE',
    NOSUBCLASS: 'Sin subclase adicionada.',
  },
  CategoryDetailsRoute: {
    PAGE_LAYOUT_CAPTION: 'Clases',
    CURRENT_PAGE_TITLE: 'Detalles',
  },
  AssociateAttributeModelsToCategory: {
    UNABLE_ASSOCIATE_ATTRIBUTE_CATEGORY: 'No se pueden asociar atributos a la clase',
    UNABLE_TO_EDIT_ATTRIBUTES: 'No se pueden editar los atributos',
    ATTRIBUTE_ADDED: 'Atributos agregados',
    ATTRIBUTE_EDITED: 'Atributos editados',
    ATTRIBUTE_ADDED_SINGLE: 'Atributo agregado',
    ATTRIBUTE_EDITED_SINGLE: 'Atributo editado',
  },
  AssociateAttributeModalView: {
    TITLE: 'Agregar atributos',
  },
  AssociateAttributeModalActionsView: {
    COUNTER_LABEL_SINGLE: 'atributo seleccionado',
    COUNTER_LABEL: 'atributos seleccionados',
    CANCEL_LABEL: 'CANCELAR',
    SUBMIT_LABEL: 'AGREGAR',
  },
  CategoryAttributesCardView: {
    CARD_TITLE: 'Attributos',
    BUTTON_LABEL: 'AGREGAR ATRIBUTOS',
    BUTTON_TOOLTIP:
      'No puede agregar atributos a una clase que ya tiene productos. Si desea más atributos, debe crear una nueva clase.',
  },
  CategoryAttributesTable: {
    TITLE_HEADER_TABLE: 'Título',
    DESCRIPTION_HEADER_TABLE: 'Descripción',
    ACCESS_HEADER_TABLE: 'Acceso',
    EMPTY_TABLE_MESSAGE: 'Los atributos adicionados se mostrarán aquí.',
  },
  CategoryAttributesTableRowView: {
    REQUIRED: 'Requerido',
    OPTIONAL: 'Opcional',
  },
  CategoryDetailsLastEditedView: {
    TITLE: 'Última edición',
  },
  CategoryDetailsProductView: {
    TITLE: 'Productos en la clase',
  },
  CategoryDetailsCardView: {
    DETAILS: 'Detalles',
    NAME: 'Nombre',
    DESCRIPTION: 'Descripción',
    SEMANTIC_ID: 'Semantic ID',
    COPIED: 'Copiado!',
  },
  AttributesRoute: {
    TITLE: 'Atributos',
    BUTTON_ADD_ATTRIBUTE: 'Nuevo atributo',
    CAPTION: 'Administra todos tus atributos.',
  },
  AttributeCard: {
    DATA_TYPE: 'Tipo de datos',
    DATA_TYPE_TOOLTIP: 'Qué tipo de datos puede recibir este atributo.',
    GROUP: 'Grupo',
    EDIT: 'Editar',
    STRUCTURE: 'Estructura',
    STRUCTURE_TOOLTIP: 'A qué estructura pertenece este atributo.',
  },
  AttributeCardAccordion: {
    ATTRIBUTES: 'Attributos',
  },
  FiltersModal: {
    APPLY_BUTTON: 'Aplicar',
    CANCEL_BUTTON: 'Cancelar',
  },
  AttributeCreatedDateFilterView: {
    INVALID_DATE_ERROR: 'Fecha inválida',
    MAX_START_DATE_ERROR: 'La fecha de inicio no podrá ser posterior a la fecha de hoy',
    MAX_START_DATE_ERROR_WITH_END_DATE:
      'La fecha de inicio no podrá ser posterior a la fecha de finalización',
    MIN_END_DATE_ERROR_WITH_START_DATE:
      'Fecha de finalización no puede ser antes de la fecha de inicio.',
    MIN_END_DATE_ERROR: 'Fecha de finalización no puede ser antes de 01/01/1899.',
    MIN_START_DATE_ERROR: 'La fecha de inicio no puede ser antes de 01/01/1899.',
    MAX_END_DATE_ERROR: 'Fecha de finalización no podrá ser posterior a la fecha de hoy.',
    START_DATE: 'Fecha de inicio',
    END_DATE: 'Fecha de final',
  },
  TabWrapper: {
    TITLE: 'Este producto tiene',
    LEGACY: 'Atributos legados',
    DYNAMIC: 'Atributos dinámicos',
    NO_RESULT_INITIAL: 'Esta clase no tiene',
    NO_RESULT_MIDDLE: 'quieres',
    NO_RESULT_FINAL: '?',
    NO_RESULT_LINK: 'crear',
  },
  AttributeFilters: {
    ATTRIBUTE_CREATED_DATE_FILTER: 'Criado em',
    ATTRIBUTE_DATA_TYPE_FILTER: 'Tipo de dados',
    FILTER: 'Filtros',
    SEARCH_ATTRIBUTE_PLACEHOLDER: 'Título do atributo',
    SEARCH_BUTTON: 'Busca',
  },
  FilterChipList: {
    ATTRIBUTE_DATA_TYPE_FILTER: 'Tipo de dados',
    ATTRIBUTE_CREATED_DATE_FILTER: 'Criado em',
    ATTRIBUTE_START_DATE_FILTER: 'Fecha de inicio',
    ATTRIBUTE_END_DATE_FILTER: 'Fecha de finalización',
  },
  FilterChipListView: {
    ATTRIBUTE_START_DATE_FILTER_CHIP: 'Desde',
    ATTRIBUTE_END_DATE_FILTER_CHIP: 'Hasta',
    ATTRIBUTE_START_AND_END_DATE_FILTER: 'a',
  },
  AttributeGroupInputView: {
    ADD_ATTRIBUTE: 'Agregar atributo *',
    ORDER_CHOICE_ATTRIBUTES: 'El orden en la pantalla dependerá de la selección',
    SELECT_ATTRIBUTE: 'Seleccionar atributo',
  },
  AttributeGroupInputListItemView: {
    REMOVE: 'Eliminar',
  },
  AttributeHeaderView: {
    LABEL_ADD_ATTRIBUTE: 'Crear Nuevo Atributo',
    TITLE_ATTRIBUTES: 'Atributos',
  },
  AttributeTablePagination: {
    ROWS_PER_PAGE_LABEL: 'Cartas por página',
  },
  AttributeTableView: {
    LIST: 'Lista',
    GRID: 'Grupo',
  },
  AttributeListView: {
    TITLE: 'Título del atributo',
    DESCRIPTION: 'Descripción',
    DATA_TYPE: 'Tipo de datos',
    STRUCTURE: 'Estructura',
    SEMANTIC_ID: 'Identificación semántica',
    SEMANTIC_ID_TOOLTIP: 'Identificador de atributo único',
  },
  AttributeTableRow: {
    EDIT: 'Edición',
  },
  AttributeType: {
    FREE_TEXT: 'Texto libre',
    GROUP: 'Grupo',
    SINGLE_SELECT_DROPDOWN: 'Selección simple menú cascada',
    RADIO_BUTTON: 'Radio Button',
  },
  NewAttributeView: {
    DETAILS: 'Detalles',
    ATTRIBUTES: 'Atributos',
    CANCEL_LABEL: 'Cancelar',
    CREATE_BUTTON_LABEL: 'Crear',
    SAVE_BUTTON_LABEL: 'Guardar',
    DISCARD_MODAL_TITLE: 'Descartar atributo',
    DISCARD_MODAL_CONTENT: `¿Estás seguro/a de querer descartar este producto? Toda la información adicionada se perderá.`,
    CONFIRM_LABEL: 'Descartar',
    DISCARD_BUTTON_LABEL: 'Descartar',
  },
  NewAttributeFormView: {
    FORM_TITLE: 'Nuevo atributo',
    SUBMIT_LABEL: 'Guardar',
    CANCEL_LABEL: 'Cancelar',
    LABEL_NAME_INPUT: 'Nombre',
    LABEL_TITLE_INPUT: 'Título *',
    LABEL_DESCRIPTION_INPUT: 'Descripción *',
    LABEL_TYPE_INPUT: 'Tipo',
    LABEL_FIELD_ARRAY_VALUE: 'Valores *',
    PLACEHOLDER_ENTER_VALUE: 'Ingresar valor',
    REQUIRED_MESSAGE: 'Requerido',
    ERROR_DUPLICATED_VALUE: 'El valor introducido ya se está utilizando',
    MORE_THAN_ONE_MESSAGE: 'Para continuar, agregue más de 1 valor',
    INVALID_DATE_MESSAGE: 'Fecha invalida',
    GROUP_MUST_CONTAIN_LEAST_TWO_ATTRIBUTES: 'Un grupo debe contener al menos 2 atributos',
    LABEL_FIELD_TYPE_INPUT: 'Tipo de campo *',
    LABEL_DATA_TYPE_INPUT: 'Tipo de datos *',
    TITLE_ENTERED_IS_ALREADY_ASSOCIATED_ATTRIBUTE:
      'El título ingresado ya está asociado a un atributo',
    EDIT_FORM_TITLE: 'Editar atributo',
    LABEL_TOOLTIP_INPUT: 'Información',
    IS_DYNAMIC: '¿Este atributo es dinámico?',
    IS_DYNAMIC_TOOLTIP: 'Atributos cuya posición se puede cambiar a nivel de clase.',
  },
  NewAttributesView: {
    TITLE: 'Nuevo atributo',
  },
  DrawerFormView: {
    INFORMATION_MISSED: 'Toda la información agregada se perderá.',
    ARE_YOU_SURE: 'Estás seguro/a que deseas cerrar?',
    CANCEL_BUTTON_LABEL: 'Cancelar',
    CREATE_BUTTON_LABEL: 'Crear',
  },
  DialogView: {
    CANCEL_BUTTON_LABEL: 'Cancelar',
    CONFIRM_BUTTON_LABEL: 'Cerrar',
  },
  CreateAttributeUseCase: {
    NEW_ATTRIBUTE_CREATED: 'Nuevo atributo creado',
  },
  CategoryDetailsPath: {
    TITLE: 'Jerarquía de clase',
  },
  AttributeDetails: {
    DETAILS: 'Detalles',
    ATTRIBUTES: 'Atributos',
    GROUP: 'Grupo',
    SINGLE_SELECT_DROPDOWN: 'Selección simple menú cascada',
    FREE_TEXT: 'Texto libre',
    STRING: 'Línea',
    NUMERIC: 'Númerico',
    DATE: 'Fecha',
    DESCRIPTION: 'Descripción',
    TOOLTIP: 'Información',
    FIELD_TYPE: 'Tipo de campo',
    DATA_TYPE: 'Tipo de datos',
    VALUES: 'Valores',
    RADIO_BUTTON: 'Radio Button',
    SEMANTIC_ID: 'Identificación semántica',
    COPY_MESSAGE: 'Copiado!',
  },
  AttributeStructureTag: {
    LEGACY: 'Legado',
    DYNAMIC: 'Dinámico',
  },
  DynamicAttributeListView: {
    TABLE_HEADER_ATTRIBUTE_NAME: 'Nombre del Atributo',
    TABLE_HEADER_ATTRIBUTE_DESCRIPTION: 'Descripción',
    TABLE_HEADER_ATTRIBUTE_OPTIONAL: 'Opcional',
    TABLE_HEADER_ATTRIBUTE_REQUIRED: 'Requerido',
    TABLE_HEADER_ATTRIBUTE_FORMAT: 'Formato',
  },
  DynamicAttributeRowView: {
    TABLE_CELL_REQUIRED: 'Requerido',
    TABLE_CELL_OPTIONAL: 'Opcional',
    REQUIRED_ICON_IMAGE: 'Ícono requerido',
    OPTIONAL_ICON_IMAGE: 'Icono opcional',
  },
  DynamicAttributeListModalView: {
    TITLE: 'Nueva clase',
    SUBTITLE:
      'Ahora administre la posición y el formato de cada atributo dinámico de su nueva clase.',
    BUTTON_GO_BACK: 'Regresa',
    BUTTON_CONFIRM: 'Confirmar',
  },
  AssociateAttributeModalItemView: {
    STATUS_DYNAMIC: 'Dinámico',
    STATUS_LEGACY: 'Legado',
  },
  FormikInputBoolean: {
    YES: 'Sí',
    NO: 'No',
    NONE: 'Ninguno',
  },
};

export default es419;
