/* eslint-disable react-hooks/rules-of-hooks */
import * as Grid from '@admin-portal-shared-components/grid';
import {
  createGenerateClassName,
  CssBaseline,
  StylesProvider,
  ThemeProvider,
} from '@material-ui/core';
import { useFeatureToggle } from 'admin-portal-shared-services';
import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { PIM_SIDE_MENU } from './config/featureToggles';
import { EnvProvider } from './context/EnvProvider';
import { EnvConfig } from './context/EnvProvider/EnvProvider.types';
import { I18nLanguageProvider } from './i18n/LanguageProvider';
import Router from './Router';
import theme from './themes';

const generateClassName = createGenerateClassName({
  productionPrefix: 'pim-taxonomy-mfe',
  seed: 'pim-taxonomy-mfe',
});

function App(props: EnvConfig): JSX.Element {
  const pimSideMenuFT = useFeatureToggle(PIM_SIDE_MENU);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline>
        <StylesProvider generateClassName={generateClassName}>
          <EnvProvider env={props}>
            <I18nLanguageProvider>
              <DndProvider backend={HTML5Backend}>
                <Grid.Container
                  style={{
                    rowGap: '1rem',
                  }}
                  type="fluid"
                  sidebar={pimSideMenuFT}
                >
                  <Router />
                </Grid.Container>
              </DndProvider>
            </I18nLanguageProvider>
          </EnvProvider>
        </StylesProvider>
      </CssBaseline>
    </ThemeProvider>
  );
}

export default App;
