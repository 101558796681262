export const DEFAULT_PLP_PAGE_SIZE = 25;
export const MAX_PAGE_SIZE = 1000000;
export const SINGLE_SELECT_DROPDOWN = 'SINGLE SELECT DROPDOWN';
export const FREE_TEXT = 'FREE TEXT';
export const GROUP = 'GROUP';
export const RADIO_BUTTON = 'RADIO BUTTON';
export const CREATE_ATTRIBUTE_FORM = 'create-attribute-form';
export const DEFAULT_IMAGE_PLACEHOLDER_URL =
  'https://cms-non-prod.global.ssl.fastly.net/media/06-24-2021-b7e68d65-14bd-4efb-a083-e2874bb67252-f3224a9b.jpeg';
export const VENDOR_APP_TIER_BASE_URL = '/api/v1/vendor-management-app-tier';
export const PREFERRED_LANGUAGE = 'PIM_PreferredLanguage';
